import { readPaymentMethodsCategories } from '../../infrastructure/store';
import type { PaymentMethodId } from '../types';
import { getItemById } from '~/src/shared/lib';

export const useGetPaymentMethodData = () => {
	return (id: PaymentMethodId) => {
		const categories = readPaymentMethodsCategories();

		if (!categories) {
			return null;
		}

		for (const category of categories) {
			const item = getItemById(id, category.items);

			if (item) {
				return {
					...item,
					category,
				};
			}
		}

		return null;
	};
};
